import Layout from '@components/layout/Layout';
import Container from '@components/ui/container/Container';
import Footer from '@components/ui/footer/Footer';
import Header from '@components/ui/header/Header';
import TitleBar from '@components/ui/title-bar/TitleBar';
import LanguageProvider from '@lib/language.provider';
import { i18n } from '@lingui/core';
import { Trans } from '@lingui/macro';
import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';

const KancelariaPage = ({ pageContext, location }) => {
	i18n.activate(pageContext.language);

	return (
		<LanguageProvider>
			<Layout>
				<main className="min-h-screen">
					<Header location={location.pathname} />
					<div className="flex flex-col md:min-h-screen">
						<TitleBar>
							<div className="justify-between items-center md:flex">
								<div className="w-full md:max-w-xs lg:max-w-sm text-white md:pr-10">
									<h1 className="mb-5 font-serif text-4xl font-bold">
										<Trans>Kancelaria</Trans>
									</h1>
									<p>
										<Trans>
											Niezależny doradca prawny w firmach{' '}
											<span className="whitespace-nowrap">z sektora </span>
											telekomunikacyjnego i IT.
										</Trans>
									</p>
								</div>
								<div className="flex items-center mt-5 w-full lg:w-1/2 space-x-4 md:mt-0 xl:pl-16">
									<div className="flex items-center">
										<StaticImage
											className="block w-20 md:w-24 lg:w-36"
											placeholder={'none'}
											src={'../assets/images/icons/20-icon.svg'}
											alt={`20-icon`}
										/>
									</div>
									<h2 className="font-serif font-bold text-white md:text-xl lg:text-2xl">
										<Trans>
											Ponad 20 lat doświadczenia{' '}
											<span className="whitespace-nowrap">w rozwiązywaniu</span>{' '}
											zagadnień prawnych firm.
										</Trans>
									</h2>
								</div>
							</div>
						</TitleBar>
						<Container>
							<div className="grid gap-4 py-14 w-full md:grid-cols-2 lg:gap-20">
								<div>
									<p className="mb-10">
										<Trans>
											Posiadam ponad dwudziestoletnie doświadczenie{' '}
											<span className="whitespace-nowrap">w obsłudze </span>
											prawnej przedsiębiorców zdobyte w wiądących firmach
											telekomunikacyjnych{' '}
											<span className="whitespace-nowrap">w kraju.</span>
										</Trans>
									</p>
									<p className="mb-4 text-sm">
										<Trans>
											Specjalizuję się w kontraktach na reemisję programów
											telewizyjnych{' '}
											<span className="whitespace-nowrap">i usług</span>{' '}
											medialnych. Jestem autorką szeregu wzorców umownych na
											świadczenie usług telekomunikacyjnych. W ramach obsługi
											prawnej przedsiębiorców wprowadzałam na rynek nowe
											produkty{' '}
											<span className="whitespace-nowrap">i usługi.</span>
										</Trans>
									</p>
									<p className="mb-4 text-sm">
										Zajmuję się również zagadnieniami związanymi{' '}
										<span className="whitespace-nowrap">
											z restrukturyzacją{' '}
										</span>
										spółek prawa handlowego, przekształceniami, podziałami i
										likwidacjami. Posiadam szerokie doświadczenie{' '}
										<span className="whitespace-nowrap">w zakresie </span>
										realizacji audytów prawnych (due diligence) spółek prawa
										handlowego, zarówno po stronie podmiotu sprzedającego jak i
										kupującego.
									</p>
									<p className="mb-4 text-sm">
										<Trans>
											W latach 2006 oraz 2014 zarządzając Biurem Prawnym
											Multimedia Polska S.A. współuczestniczyłam{' '}
											<span className="whitespace-nowrap">
												w przygotowywaniu
											</span>{' '}
											prospektów emisyjnych akcji spółki, a także{' '}
											<span className="whitespace-nowrap">w procesie</span> ich
											delistingu. Pełniłam kluczową rolę{' '}
											<span className="whitespace-nowrap">w procesie </span>
											sprzedaży akcji spółki na rzecz Vectra S.A.{' '}
											<span className="whitespace-nowrap">
												w roku
											</span> 2019.{' '}
											<span className="whitespace-nowrap">W Multimedia</span>{' '}
											Polska S.A. odpowiedzialna byłam także za realizację
											obowiązków informacyjnych na GPW.
										</Trans>
									</p>
								</div>
								<div className="md:px-8">
									<StaticImage
										className="block mb-3"
										placeholder={'none'}
										src={'../assets/images/katarzyna-iwanejko-taczkowska.jpg'}
										alt={`20-icon`}
									/>
									<p className="text-sm font-bold">
										Katarzyna Iwanejko-Taczkowska
									</p>
								</div>
							</div>
						</Container>
						<Footer />
					</div>
				</main>
			</Layout>
		</LanguageProvider>
	);
};

export default KancelariaPage;
